export const bcBlue = '#003366';
export const bcYellow = '#FCBA19';
export const black = '#16161D';

export const RECORD_COLOURS = [
  '#FFFFFF',
  '#2A81CB',
  '#FFD326',
  '#CB2B3E',
  '#2AAD27',
  '#CB8427',
  '#CAC428',
  '#9C2BCB',
  '#7B7B7B',
  '#3D3D3D'
];
